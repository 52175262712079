import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Roles, RoomService } from './helper/enumerators';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuardService],
    data: {
      page: 'home'
    },
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'demo/demo',
    loadChildren: () => import('./demo/demo/demo.module').then(m => m.DemoPageModule)
  },
  {
    path: 'dialer',
    canActivate: [AuthGuardService],
    data: {
      page: 'phone'
    },
    loadChildren: () => import('./dialer/dialer.module').then(m => m.DialerPageModule)
  },
  {
    path: 'conversation',
    canActivate: [AuthGuardService],
    data: {
      page: 'chat'
    },
    loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationPageModule)
  },
  {
    path: 'conversation/chat',
    canActivate: [AuthGuardService],
    data: {
      page: 'chat'
    },
    loadChildren: () => import('./conversation/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'conversation/bot-chat',
    canActivate: [AuthGuardService],
    data: {
      page: 'chat'
    },
    loadChildren: () => import('./conversation/bot-chat/bot-chat.module').then(m => m.BotChatPageModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./token-auth/token-auth.module').then(m => m.TokenAuthPageModule)
  },
  {
    path: 'wakeup',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./wakeup/wakeup.module').then(m => m.WakeupPageModule)
  },
  {
    path: 'checkout',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./staff-user/staff-user.module').then(m => m.StaffUserPageModule)
  },
  {
    path: 'room-service-request',
    loadChildren: () => import('./room-service-request/room-service-request.module').then(m => m.RoomServiceRequestPageModule)
  },
  {
    path: 'place',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./place/place.module').then(m => m.PlacePageModule)
  },
  {
    path: 'trip-vault',
    canActivate: [AuthGuardService],
    data: {
      page: 'tripPlanner'
    },
    loadChildren: () => import('./trip-vault/trip-vault.module').then(m => m.TripVaultPageModule)
  },
  {
    path: 'guest-dialer/:roomId/:apiKey',
    data: {
      page: 'phone'
    },
    loadChildren: () => import('./dialer/dialer.module').then(m => m.DialerPageModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
