import { Injectable } from '@angular/core';
import { PhoneService } from './phone.service';
import { TimeService } from './time.service';
import { KeyPadToneService } from './keypadtone.service';

@Injectable({
  providedIn: 'root'
})
export class KeypadService {
  public keypadShowHide = true;
  constructor(
    private timeService: TimeService,
    private phoneService: PhoneService,
    private keyPadToneService: KeyPadToneService
  ) { }

  public pressDigit(digit: string): void {
    // this.keyPadToneService.playDtmf(digit);
    this.phoneService.keypadModel.next({
      to: this.phoneService.keypadModel.value.to + digit, showKeypad: true
    });
    if (this.phoneService.isCalling$.value?.callStatus === 'connected') {
      this.phoneService.sendDtmf(digit);
    }
    setTimeout(() => {
      this.keyPadToneService.stopDtmf();
    }, 100)
  }

  public async pressDigitWait(digit: string): Promise<void> {
    this.phoneService.keypadModel.next({
      to: this.phoneService.keypadModel.value.to + digit, showKeypad: true
    });
  }

  public pressClear(): void {
    this.phoneService.keypadModel.next({
      to: '', showKeypad: true
    });
  }

  public pressBackSpace(): void {
    this.phoneService.keypadModel.next({
      to: this.phoneService.keypadModel.value.to.slice(0, -1), showKeypad: true
    });
  }
}
