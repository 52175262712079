import { Injectable } from '@angular/core';
import {
  ActionPerformed as CapActionPerformed,
  PushNotificationSchema,
  PushNotifications,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ActionPerformed, LocalNotifications, Channel } from '@capacitor/local-notifications';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DeeplinkService } from './deeplink';
import { Messaging, getToken, onMessage,  } from '@angular/fire/messaging';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
declare let window: any;


@Injectable({
  providedIn: 'root',
})
export class FcmProvider {

  devices: Array<any> = [];
  request: any;
  token: string = null
  uid: string = null
  private _serviceWorkerRegistration: any;
  constructor(
    private util: UtilService,
    private http: HttpClient,
    private platform: Platform,
    private router: Router,
    private deeplink: DeeplinkService,
    private msg: Messaging
  ) { }

  async init(uid: string) {
    console.log('fcm ========= uid', uid)
    return new Promise(async (resolve, reject) => {
      try {
        this.setNotificationActions();
        if (this.platform.is('capacitor')) {
          this.request = await PushNotifications.requestPermissions();
          console.log('has permission  =====', this.request);
          try {
            if (this.request.receive) {
              const _serviceWorkerRegistration = await navigator.serviceWorker
                .register("firebase-messaging-sw.js", {
                  type: "module",
                })
                console.log('has _serviceWorkerRegistration  =====', _serviceWorkerRegistration);
              this._serviceWorkerRegistration = _serviceWorkerRegistration;
              await this.processToken(uid)
            } else {
              console.log('not have permission for token');
            }
          } catch (error) {
            console.log('error while _serviceWorkerRegistration', error);
          }
        } else {
          if (!this.platform.is('ios') && !/Mac|iPod|iPhone|iPad/.test(navigator.platform)) {
            Notification.requestPermission().then(
              async (notificationPermissions: NotificationPermission) => {
                if (notificationPermissions === "granted") {
                  this.request = {
                    'receive': true
                  };
                  const _serviceWorkerRegistration = await navigator.serviceWorker
                  .register("firebase-messaging-sw.js", {
                    type: "module",
                  })
                  this._serviceWorkerRegistration = _serviceWorkerRegistration;
                  await this.processToken(uid)
                }
                if (notificationPermissions === "denied") {
                  throw new Error("Permission denied");
                }
              });

          } else {
            await this.checkIosPermission(uid)
          }
        }
        resolve({})
      } catch (ex) {
        reject({
          error: ex.error || ex.message || ex
        })
      }
    })
  }

  async checkIosPermission(uid) {
    if ('safari' in window && 'pushNotification' in window.safari) {
      this.uid = uid
      let permission: any = window.safari.pushNotification.permission(environment.app_web_url);
      await this.checkSafariPermission(permission)
    }
  }

  async checkSafariPermission(permissionData: any) {
    try {
      if (permissionData.permission == "default") {
        window.safari.pushNotification.requestPermission(
          environment.app_web_url,
          environment.app_web_url,
          {},
          this.checkSafariPermission
        )
      } else if (permissionData.permission === 'denied') {
        throw new Error("permission denied")
      } else if (permissionData.permission === 'granted') {
        await this.processToken(this.uid)
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  listenNotificationEvent() {
    LocalNotifications.addListener('localNotificationActionPerformed', async (notificationAction: ActionPerformed): Promise<any> => {
      await PushNotifications.removeAllDeliveredNotifications()
      await this.deeplink.action(notificationAction.actionId)
    });
  }

  async setNotificationActions() {
    try {
      await LocalNotifications.checkPermissions();
      if (this.platform.is('capacitor') && this.platform.is('android')) {
        const channelNotification: Channel = {
          id: 'call-notification',
          name: 'call-notifications',
          importance: 4,
          visibility: 1,
          vibration: true,
        };
        await PushNotifications.createChannel(channelNotification)
        await LocalNotifications.createChannel(channelNotification)
      }
    } catch (ex) {
      throw new Error(ex.error || ex.message || ex)
    }
  }

  async processToken(uid: string) {
    console.log('uid ========', uid);
    return await new Promise(async (resolve, reject) => {
      try {
        if (this.request['receive']) {
          const token: any = await this.getToken(this._serviceWorkerRegistration);
          const resp: any = await this.saveToken(uid, token);
          this.showPushNOtification();
          resolve(resp)
        } else {
          throw new Error('Push notification error on register' + this.request);
        }
      } catch (ex) {
        reject({
          error: ex.error || ex.message || ex
        })
      }
    })
  }

  async getToken(serviceWorkerRegistration) {
    return new Promise((resolve) => {
      getToken(this.msg, {
        vapidKey: `BIGEeFK9YgWBWnHQm79xizfSWTyyg-VJDWWyzbLRRy4t8TTeIms1ZtL0XQctRRi8P6zYnR09UK9t0YDNhuH4ltQ`,
        serviceWorkerRegistration: serviceWorkerRegistration,
      }).then(async (x) => {
        console.log('my fcm token', x);
        resolve(x)
        // This is a good place to then store it on your database for each user
      });
    })
  }

  async saveToken(uid: string, token: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.token = token;
        this.http.put(
          `${environment.API_ENDPOINT}guest/me/${uid}`, {
          'fcmToken': token,
          appType: (!this.platform.is('capacitor')) ? 'PWA' : (this.platform.is('capacitor') && this.platform.is('android')) ? 'ANDROID' : 'IOS'
        }
        ).toPromise().then((res: any) => {
          resolve(res);
        }).catch((ex: any) => {
          throw new Error(ex);
        })
      } catch (ex) {
        reject({
          error: ex.error || ex.message || ex
        })
      }
    });
  }

  async getPushNotification() {
    return await new Promise((resolve, reject) => {
      try {
        if (this.platform.is('capacitor')) {
          PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
              resolve(notification);
            }
          );
          PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: CapActionPerformed) => {
              this.listenNotificationEvent();
              await PushNotifications.removeAllDeliveredNotifications();
            }
          );
        } else {
          new Observable((sub) => onMessage(this.msg, (msg) =>
            sub.next(msg))).pipe(
              tap((msg) => {
                console.log("My Firebase Cloud Message", msg);
                resolve(msg);
              }))
        }
      } catch (ex) {
        if (this.platform.is('capacitor')) {
          PushNotifications.addListener('registrationError', (error: any) => {
            reject('Error on registration: ' + JSON.stringify(error));
          });
        } else {
          reject('Error on registration: ' + JSON.stringify(ex));
        }
      }
    });
  }

  showPushNOtification() {
    this.listenNotificationEvent();
    this.getPushNotification().then(async (data: any) => {
      await this.router.navigate(['dialer']);
      data.data['title'] = data.title || data.data.title
      data.data['body'] = data.body || data.data.body
      data.data['actionType'] = data.data.actionType
      data.data['CallerName'] = data.data.CallerName
      data.data['CallerID'] = data.data.CallerID
      if (data.data.actionType != "normal") data.data.title += ` : ${data.data.CallerName} - ${data.data.CallerID}`;
      await this.setLocalNotificationActionTypes(data.data['actionType'], data.data);
    }).catch((ex: any) => {
      console.info(`fcm showPushNotification =======>`, ex);
    })
  }

  private async setLocalNotificationActionTypes(actionId: string, data: any) {
    await this.util.showLocalNotification(actionId, data)
  }
}
