import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Network } from '@capacitor/network';

export enum ConnectionStatus {
  Online,
  Offline,
}


@Injectable({
  providedIn: 'root'
})

export class NetworkService {
   
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);
  internet: boolean = false;

  constructor(
  ) {
  }
 
  public async initializeNetworkEvents() {
    const status = await Network.getStatus();
    this.setStatus(status)
    Network.addListener('networkStatusChange', async (status: any) => {
      let netstatus: any = await Network.getStatus();
      this.setStatus(netstatus)
    });
  }

  setStatus(netstatus) {
    this.internet = netstatus.connected;
    (netstatus.connected === false)
      ? this.updateNetworkStatus(ConnectionStatus.Offline)
      : this.updateNetworkStatus(ConnectionStatus.Online);
  }
 
  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
    let connection = status == ConnectionStatus.Offline ? 'Offline' : 'Online';
  }
 
  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }
 
  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }
}