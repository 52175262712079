import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'static-conversation-modal',
    templateUrl: 'static-conversation-modal.html',
    styleUrls: ['static-conversation-modal.scss']
})
export class StaticConversationModalComponent implements OnInit, OnDestroy {
    staticMessage: string = '';
    staticTitle: string = '';
    constructor(private modalCtrl: ModalController) {
    }

    ngOnInit() {
        if (window?.screen?.orientation) {
           window.screen.orientation.lock('landscape');
        }
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    ngOnDestroy() {
        if (window?.screen?.orientation) {
            window.screen.orientation.lock('portrait');
        }
    }
}
