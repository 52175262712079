import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { CrudService } from './crud.service';
import { catchError, map } from 'rxjs/operators';
import { UtilService } from './util.service';
import { Router } from '@angular/router';


@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    constructor(private readonly crudService: CrudService,
        private utilService: UtilService,
        private router: Router,) {
    }

    public intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
        let headers = {
            ///  'Content-Type': 'application/json',
            'req_from': 'webJson'
        }
        const token = this.crudService.getAuthToken || localStorage.getItem('token')
        if (token) {
            const propertyId = localStorage.getItem('propertyId') || this.crudService.slugConfiguration$.value['propertyId'];
            headers['Authorization'] = `Bearer ${token}`;
            if (propertyId) {
                headers['propertyId'] = `${propertyId}`
            }
        }
        const modifiedReq = request.clone({
            setHeaders: headers
        });
        return handler.handle(modifiedReq).pipe(
            map(event => {
                if (event instanceof HttpResponse) {
                    if (event.body?.response_code === 400 || event.body?.response_code === 500) {
                        if (!event.url.includes('favourites/check') && !event.url.includes('favourites/list') && !event.url.includes('orders/create')) {
                            this.utilService.showToast(event.body.response_data);
                            return event;
                        } else if (event.url.includes('orders/create')) {
                            return event;
                        }
                    } else if (event.body?.response_code === 401) {
                        this.crudService.setSlug();
                        localStorage.clear();
                        if (this.crudService.currentUserModeValue === 'staff') {
                            this.router.navigate(['admin']);
                        } else {
                            this.router.navigate(['login']);
                        }
                        return event;
                    } else {
                        return event;
                    }
                }
            }),
            catchError(error => {
                console.error(`request-interceptor intercept`, error);
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 404:
                            error.error.errors.forEach(message => {
                                this.utilService.showToast(message);
                            });
                            return throwError(error);
                        case 400:
                            if (error.error.message) {
                                this.utilService.showToast(error.error.message);
                            } else if (error.error.errors && error.error.errors.length > 0) {
                                error.error.errors.forEach(message => {
                                    this.utilService.showToast(message);
                                });
                            }
                            return throwError(error);
                        case 401:
                            this.crudService.setSlug();
                            if (this.crudService.currentUserModeValue === 'staff') {
                                localStorage.clear();
                                this.router.navigate(['admin']);
                            } else {
                                this.router.navigate(['login']);
                            }

                    }
                }
                return of(error);
            }));
    }
}
