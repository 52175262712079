import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class KeyPadToneService {

  private audioContext: AudioContext;
  osc1: OscillatorNode;
  osc2: OscillatorNode;
  freq1: any;
  freq2: any;
  gainNode: any;
  filter!: BiquadFilterNode;
  status = 0;

  private readonly dtmfFrequencies: any = {
    "1": { f1: 697, f2: 1209 },
    "2": { f1: 697, f2: 1336 },
    "3": { f1: 697, f2: 1477 },
    "4": { f1: 770, f2: 1209 },
    "5": { f1: 770, f2: 1336 },
    "6": { f1: 770, f2: 1477 },
    "7": { f1: 852, f2: 1209 },
    "8": { f1: 852, f2: 1336 },
    "9": { f1: 852, f2: 1477 },
    "*": { f1: 941, f2: 1209 },
    "0": { f1: 941, f2: 1336 },
    "#": { f1: 941, f2: 1477 },
    "+": { f1: 941, f2: 1477 },
  }

  constructor() {
    setTimeout(() => {
      this.audioContext = new AudioContext();
      this.osc1 = this.audioContext.createOscillator();
      this.osc2 = this.audioContext.createOscillator();
      this.freq1 = 350;
      this.freq2 = 440;
      this.setup();
    }, 1000)
  }

  setup() {
    this.osc1 = this.audioContext.createOscillator();
    this.osc2 = this.audioContext.createOscillator();
    this.osc1.frequency.value = this.freq1;
    this.osc2.frequency.value = this.freq2;

    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = 0.25;

    this.filter = this.audioContext.createBiquadFilter();
    this.filter.type = "lowpass";
    this.filter.frequency.value = 8000;

    this.osc1.connect(this.gainNode);
    this.osc2.connect(this.gainNode);

    this.gainNode.connect(this.filter);
    this.filter.connect(this.audioContext.destination);
  }

  start() {
    this.setup();
    this.osc1.start(0);
    this.osc2.start(0);
    this.status = 1;
  }

  stop() {
    this.osc1.stop(0);
    this.osc2.stop(0);
    this.status = 0;
  }

  playDtmf(digit: string) {
    const frequencyPair = this.dtmfFrequencies[digit];
    this.audioContext.resume();
    this.freq1 = frequencyPair.f1;
    this.freq2 = frequencyPair.f2;

    if (this.status == 0) {
      this.start();
      setTimeout(async () => {
        this.stop();
      }, 100);
    }
  }

  stopDtmf() {
    if (this.status) {
      this.stop();
    }
  }

}
