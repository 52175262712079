import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { CrudService } from '../../services/crud.service';
import { Subscription } from 'rxjs';
import { THEME_SETTING } from '../../utils/config';
import { Geolocation } from '@capacitor/geolocation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() adminPage: boolean;
  @Input() adminHome: boolean;
  @Input() roomid: string;
  @Input() isPhoneDirectory: string;
  @ViewChild('phoneModal', {
    static: false
  }) phoneModal: any;
  orientationSubscribe: Subscription;
  propertyConfigSubscribe: Subscription;
  orientation: string = '';
  propertyConfig: any = null;
  public dialerThemeSettingOld = THEME_SETTING;
  public currentUserModeValue = '';
  private currentUserModeValueSubscriber: Subscription = null;
  private _pwaConfigurationSubscribe: Subscription;
  private _isReadyWakeWordComponentSubscribe: Subscription;
  public isReadyWakeWordComponent: boolean = false;
  public showWakeWordBtn: boolean = false;
  public canGoEmergency = false;
  constructor(public crudService: CrudService, private utilService: UtilService, private router: Router) {
    this.canGoEmergency = this.utilService.hasPermissions('staff-user:emergency');
  }

  ngOnInit() {

    this._isReadyWakeWordComponentSubscribe = this.crudService.isReadyWakeWordComponent$.pipe(
      tap((isReadyWakeWordComponent: boolean) => {
        this.isReadyWakeWordComponent = isReadyWakeWordComponent;
      })
    ).subscribe();

    this.orientationSubscribe = this.utilService.getOrientation().pipe(
      tap((orientation) => {
        this.orientation = orientation;
      })
    ).subscribe();
    this.currentUserModeValueSubscriber = this.crudService.currentUserMode.pipe((tap((currentUserModeValue) => {
      this.currentUserModeValue = currentUserModeValue
    }))).subscribe()
    this.propertyConfigSubscribe = this.crudService.propertyConfig$.pipe(
      tap((propertyConfig) => {
        this.propertyConfig = propertyConfig;
        this.dialerThemeSettingOld = this.propertyConfig?.dialerThemeSetting?.value || this.dialerThemeSettingOld;
      })
    ).subscribe();
    this._pwaConfigurationSubscribe = this.crudService.pwaConfiguration$.pipe(
      tap((pwaConfiguration) => {
        this.showWakeWordBtn = pwaConfiguration?.aiBotConfiguration?.show && this.crudService.currentUserModeValue !== 'staff';
      })
    ).subscribe();
  }

  checkWebRtcFeature() {
    if (this.crudService.propertyConfig$.value['featureWebRTC'] && this.crudService.propertyConfig$.value['featureWebRTC'].value == false) {
      return true
    }
    return false
  }


  ngOnDestroy(): void {
    this.orientationSubscribe.unsubscribe();
    this.propertyConfigSubscribe?.unsubscribe();
    this.currentUserModeValueSubscriber?.unsubscribe();
    this._isReadyWakeWordComponentSubscribe?.unsubscribe();
    this._pwaConfigurationSubscribe?.unsubscribe();
    this.phoneModal?.dismiss?.()


  }

  handleEmergency() {
    this.emergency();
  }

  async printCurrentPosition() {
    const coordinates = await Geolocation.getCurrentPosition();
    return coordinates;
  };

  async emergency() {
    if (this.canGoEmergency) {
      let coordinates;
      try {
        coordinates = await this.printCurrentPosition();
      } catch (error) {
      }
      const response = await this.crudService.emergencyTrigger({
        latitude: coordinates?.coords?.latitude,
        longitude: coordinates?.coords?.longitude
      }).subscribe();
      this.crudService.showEmergencyAlert$.next(true);
      setTimeout(() => {
        this.crudService.showEmergencyAlert$.next(false);
      }, 2000);
      setTimeout(() => {
        this.router.navigate(['admin/emergency'], { state: { showAlert: true } });
      }, 100);
    }
  }
}
