import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { CrudService } from './crud.service';
import { FcmProvider } from './fcm';
import { UtilService } from './util.service';
import { PhoneService } from '../component/webphone/phone.service';
@Injectable({
  providedIn: 'root'
})

export class GuestCallingService {

  loading: boolean = false;
  loadingText: string | undefined

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private utilService: UtilService,
    private fcm: FcmProvider,
    private phoneService: PhoneService
  ) {

  }

  get guestName() {
    return this.utilService.getParameterByName('guestName') || localStorage.getItem('name');
  }


  get token() {
    return this.utilService.getParameterByName('guestToken') || localStorage.getItem('guestDialToken');
  }

  get dialer() {
    return this.utilService.getParameterByName('dialer') || localStorage.getItem('guestDialNumber');
  }

  saveGuestToken() {
    localStorage.setItem('guestDialToken', this.token);
    localStorage.setItem('guestDialNumber', this.dialer);
    localStorage.setItem('guestDialName', this.guestName);
    this.router.navigateByUrl('/guest-dialer')
  }

  async processGuestToken({
    apiKey, roomId
  }) {
    return await new Promise((resolve, reject) => {
      try {
        this.loadingText = "Validating guest credentials"
        this.loading = true;
        this.crudService.saveDataWithApiKey('guest/validateToken', { roomId }, apiKey).pipe(
          take(1),
          tap(async (data) => {
            this.loadingText = "Setting up call service"
            const guest = data.response_data.guest;
            this.crudService.setAuthToken = guest.token
            localStorage.setItem('token', guest.token);
            localStorage.setItem('propertyId', guest.propertyId);
            localStorage.setItem('room', guest.room);
            localStorage.setItem('name', (guest.firstName || '') + ' ' + (guest.lastNAme || ''));
            localStorage.setItem('firstName', guest.firstName);
            localStorage.setItem('lastName', guest.lastName || '');
            localStorage.setItem('guestId', guest.id);
            localStorage.setItem('isGuestLogin', guest.isGuestLogin);
            localStorage.setItem('isGuestDialer', 'true');
            localStorage.setItem('dialer', localStorage.getItem('guestDialNumber') || data.response_data.dialer);
            data.response_data.propertyConfig.configuration.skipButtonCall = true;
            this.crudService.setPropertyConfig = data.response_data.propertyConfig.configuration;
            this.phoneService.keypadModel.next({
              to: this.activatedRoute.snapshot.queryParams['dialer'] || localStorage.getItem('guestDialNumber') || String(data.response_data.dialer || "") || "",
              showKeypad: true
            });
            localStorage.setItem('guestDialToken', '');
            localStorage.setItem('guestDialNumber', '');
            this.fcm.init(guest.id);
            setTimeout(() => {
              this.loading = false;
            }, 2000)

            resolve("calling started")
          })
        ).subscribe();

      } catch (error) {
        this.loading = false;
        console.error(`calling processGuestToken`, error);
        reject(error)
      }
    })
  }
}