import { Directive, EventEmitter, HostListener, Injectable, Input, Output } from '@angular/core';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective {
  @Input() longPressDuration = 500; // Duration in milliseconds
  @Output() longPress = new EventEmitter<void>();
  @Output() shortPress = new EventEmitter<void>();

  private longPressTimeout: any;
  private isLongPressing = false;

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onPressStart(event: Event): void {
    this.isLongPressing = false;
    this.longPressTimeout = setTimeout(() => {
      this.isLongPressing = true;
      this.longPress.emit();
    }, this.longPressDuration);
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  @HostListener('touchend')
  onPressEnd(): void {
    clearTimeout(this.longPressTimeout);
    if (!this.isLongPressing) {
      this.shortPress.emit();
    }
  }
}