import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonModal, NavParams, Platform } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CrudService } from '../../services/crud.service';
import { UtilService } from '../../services/util.service';
import { Subscription } from 'rxjs';
import { THEME_SETTING } from '../../utils/config';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'alice-service-modal',
  templateUrl: 'alice-service.html',
  styleUrls: ['alice-service.scss'],
})
export class AliceServiceModalComponent implements OnInit, OnDestroy {
  propertyId: string = '';
  room: string = '';
  aliceConfiguration: any = null;
  maxHeight = this._platform.height() * 0.6 + 'px';
  maxImgHeight = this._platform.height() * 0.2 + 'px';
  selectedOption: any = {};
  @ViewChild('aliceServiceQuantityModal') aliceServiceQuantityModal: IonModal;
  @ViewChild('confirmationModal') confirmationModal: IonModal;
  private _propertyConfigSubscribe: Subscription = null;
  public dialerThemeSettingOld = THEME_SETTING;
  public themeLightColor: string = '';
  public isConfirm: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private crudService: CrudService,
    private utilService: UtilService,
    private navParams: NavParams,
    private _platform: Platform,
  ) {
    if (this.navParams.get('aliceConfiguration')) {
      this.aliceConfiguration = this.navParams.get('aliceConfiguration');
    }
  }

  ngOnDestroy(): void {
      this._propertyConfigSubscribe?.unsubscribe();
  }

  ngOnInit() {
    this.propertyId = localStorage.getItem('propertyId');
    this.room = localStorage.getItem('room');
    this._propertyConfigSubscribe = this.crudService.propertyConfig$
      .pipe(
        tap(async (propertyConfig) => {
          this.dialerThemeSettingOld =
            propertyConfig?.dialerThemeSetting?.value ||
            this.dialerThemeSettingOld;
          this.themeLightColor = this.utilService.lightenColor(
            this.dialerThemeSettingOld?.headerFooterThemeColor,
            50
          ); // #cce1d6
        })
      )
      .subscribe();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async requestRoomService(selectedOption) {
    this.selectedOption = selectedOption;
    if (this.selectedOption.hasQuantity) {
      this.roomServiceQuantityType(this.selectedOption);
    } else {
      this.showConfirmation();
    }
  }

  async roomServiceType() {
    try {
      this.utilService.showLoader();
      let requestBody = {
        propertyId: this.propertyId,
        aliceServiceId: this.aliceConfiguration.aliceServiceId,
        aliceOptionId: this.aliceConfiguration.aliceOptionId,
        aliceSubOptionId: this.selectedOption.id || this.selectedOption.name,
        message: this.selectedOption.message || '',
        requester: 'Guest',
        guestId: Number(localStorage.getItem('guestId')),
        quantity: this.selectedOption.selectedQuantity  ? Number(this.selectedOption.selectedQuantity) : 0,
      };
      let response = await this.crudService
        .saveData('alice/ticket', requestBody)
        .toPromise();
      this.utilService.hideLoader();
      this.isConfirm = true;
      setTimeout(() => {
        this.isConfirm = false;
      }, 2000);
    } catch (error) {
      this.utilService.hideLoader();
      this.utilService.showToast(
        error?.error?.message || error?.message || error
      );
      this.isConfirm = false;
    }
  }

  async roomServiceQuantityType(btn: any) {
    this.selectedOption.modalId = 'alice-service-quantity';
    this.selectedOption.roomServiceQuantityTypeOpen = true;
    this.selectedOption.selectOptions = Array.from(
        { length: btn?.quantity || 0 },
        (_, index) => (`${index + 1}`)
      );
    this.selectedOption.selectedQuantity = this.selectedOption?.selectOptions
      ?.length
      ? this.selectedOption.selectOptions[0]
      : 0;
    this.aliceServiceQuantityModal.cssClass = 'alice-service-quantity-modal';
    this.aliceServiceQuantityModal.present();
  }

  async showConfirmation() {
    this.closeAliceServiceQuantityModal();
    this.confirmationModal.cssClass = 'confirmation-modal';
    this.confirmationModal.present();
  }

  async onYes() {
     this.confirmationModal.dismiss(null);
    await this.roomServiceType();
  }

  onNo() {
     this.confirmationModal.dismiss(null);
  }

  closeAliceServiceQuantityModal() {
    this.aliceServiceQuantityModal.dismiss();
  }
}
