import { Injectable } from '@angular/core';
import { Haptics } from '@capacitor/haptics';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Platform, ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CrudService } from './crud.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { GenericResponse, VoiceRecorder } from 'capacitor-voice-recorder';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  public loader = null;
  interval: any;
  _interval: any;
  conversationtype = [
    {
      title: 'Front Desk',
      media: '../assets/icon/front-desk-90.png',
    },
    {
      title: 'Housekeeping',
      media: '../assets/icon/cleaning.png',
    },
    {
      title: 'Concierge',
      media: '../assets/icon/concierge.png',
    },
    {
      title: 'Spa',
      media: '../assets/icon/foot-massage.png',
    },
  ];

  botConversationtype = [
    {
      title: 'Front Desk',
      media: '../assets/icon/front-desk-90.png',
      type: 'front-desk',
      welcomeMsg: 'Hi, Welcome to front desk help.',
    },
    {
      title: 'Housekeeping',
      media: '../assets/icon/cleaning.png',
      type: 'house-keeping',
      welcomeMsg: 'house keeping',
    },
    {
      title: 'Room Service',
      media: '../assets/icon/concierge.png',
      type: 'room-service',
      welcomeMsg: 'Hi, Welcome to room service help.',
    },
    {
      title: 'Wifi',
      media: '../assets/icon/concierge.png',
      type: 'wifi',
      welcomeMsg: 'Hi, Welcome to wifi related help.',
    },
    {
      title: 'Spa',
      media: '../assets/icon/foot-massage.png',
      type: 'spa',
      welcomeMsg: 'Hi, Welcome to spa service help.',
    },
    {
      title: 'ChatGPT',
      media: '../assets/icon/chat-gpt.jpg',
      type: 'ChatGpT',
      welcomeMsg: 'chatgpt',
    },
  ];

  public hasMicrophonePermission$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  constructor(
    public platform: Platform,
    private toast: ToastController,
    private loadingController: LoadingController,
    private crudService: CrudService
  ) {}

  showToast(message: string, position: 'top' | 'bottom' | 'middle' = 'top') {
    this.toast
      .create({
        position,
        message: message,
        duration: 2000,
        cssClass: 'toast-ion',
      })
      .then((toastData) => {
        toastData.present();
      });
  }

  async showLoader(message: string = null) {
    if (!this.loader) {
      this.loader = await this.loadingController.create({
        message: message !== null ? message : 'Please wait...',
      });
      await this.loader.present();
    } else {
      console.info(`Loader already present`);
    }
  }

  hideLoader() {
    this.loadingController
      .dismiss()
      .then((res) => {
        console.info(`Loader dismiss`, res);
        this.loader = null;
      })
      .catch((error) => {
        console.info('util.service Error on loading', error);
      });
  }

  async loadSlugConfig() {
    const slug = this.crudService.slug.getValue();
    if (!slug) {
      throw Error('No slug found');
    }
    const slugConfig = await this.crudService
      .getData(`property/ids-by-slug/${this.crudService.slug.getValue()}`)
      .toPromise();
    console.log('slugConfig', slugConfig);
    if (!Object.keys(slugConfig?.response_data).length) {
      throw Error('No slug found');
    }
    this.crudService.setSlugConfiguration = slugConfig?.response_data;
  }

  async checkPermissions() {
    try {
      const result: GenericResponse =
        await VoiceRecorder.requestAudioRecordingPermission();
      console.log('Had audio record permissions', result.value);
      this.hasMicrophonePermission$.next(result.value);
      await LocalNotifications.checkPermissions();
    } catch (error) {
      console.log('error ======== checkPermissions', error);
    }
  }

  callnotifications = [];
  notificationLoop = false;

  async callNotificationManager(actionId: string, data: any) {
    this.notificationLoop = true;
    this.showLocalNotification(actionId, data);
    const loopInterval = setInterval(() => {
      if (!this.notificationLoop) {
        clearInterval(loopInterval);
      } else {
        this.showLocalNotification(actionId, data);
        if (this.callnotifications.length > 1) {
          LocalNotifications.cancel(this.callnotifications[0]);
        }
      }
    }, 5000);
  }

  async clearNotifications() {
    try {
      this.notificationLoop = false;
      await PushNotifications.removeAllDeliveredNotifications();
    } catch (error) {
      // console.log(`Error clearNotifications`, error)
    }
  }

  async showLocalNotification(actionId: string, data: any) {
    try {
      if (this.platform.is('capacitor')) {
        LocalNotifications.registerActionTypes({
          types: [
            {
              id: 'Call_Request',
              actions: [
                {
                  id: 'declinecall',
                  title: 'Decline',
                  destructive: true,
                },
                {
                  id: 'acceptcall',
                  title: 'Accept Call',
                  destructive: true,
                },
              ],
            },
            {
              id: 'Ongoing_Call',
              actions: [
                {
                  id: 'declinecall',
                  title: 'Hangup',
                  destructive: true,
                },
              ],
            },
            {
              id: 'Missed_Call',
              actions: [
                {
                  id: 'callback',
                  title: 'Call Back',
                  destructive: true,
                },
              ],
            },
            {
              id: 'normal',
            },
          ],
        });
        let actionType: any =
          data.title == 'Incoming call'
            ? 'Call_Request'
            : data.title == 'Missed call'
            ? 'Missed_Call'
            : actionId;
        let config: any = {
          actionTypeId: actionType,
          title: data.title,
          body: data.body,
          autoCancel: true,
          ongoing: false,
          id: new Date().getUTCMilliseconds(),
        };

        this.callnotifications.push(config.id);

        await PushNotifications.removeAllDeliveredNotifications();
        if (actionType == 'Call_Request') {
          config['autoCancel'] = false;
          config['channelId'] = 'call-notification';

          await LocalNotifications.schedule({ notifications: [config] });

          // App.addListener('appStateChange', async ({ isActive }) => {
          //   if(isActive == false) {
          //     await LocalNotifications.schedule({ notifications: [config ] });
          //     this._interval = setInterval(async () => {
          //       await Haptics.vibrate({ duration: 500 });
          //     }, 1000)
          //     this.interval = setInterval(async () => {
          //       await LocalNotifications.schedule({ notifications: [config ] });
          //     }, 5000)
          //     setTimeout(() => {
          //       clearInterval(this._interval);
          //       clearInterval(this.interval);
          //     }, 15000)
          //   }
          // });
        } else {
          await LocalNotifications.schedule({ notifications: [config] });
          await Haptics.vibrate({ duration: 1000 });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  checkFeature(property: string) {
    if (
      this.crudService.propertyConfig$.value[property] &&
      this.crudService.propertyConfig$.value[property].value == true
    ) {
      return true;
    }
    return false;
  }

  extractContent(html) {
    const span = document.createElement('span');
    span.innerHTML = html;
    return span.textContent || span.innerText;
  }

  getConversationByTitle(title, conversations) {
    for (let index = 0; index < conversations.length; index++) {
      const element = conversations[index];
      if (element.title == title) {
        return element;
      }
    }
    return null;
  }

  private _getOrientation = () =>
    window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  private _setOrientation: BehaviorSubject<any> = new BehaviorSubject(
    this._getOrientation()
  );

  setOrientation() {
    return this._setOrientation.next(this._getOrientation());
  }

  getOrientation() {
    return this._setOrientation.asObservable();
  }

  getParameterByName(name: string, url: string = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  hasPermissions(permission: string) {
    const permissions = this.crudService.currentUserValue?.permissions || {};
    return !!permissions[permission];
  }

  isMobileDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    return false;
  }

  randomString(length: number): string {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  generateLivekitRoomId(): string {
    return `${this.randomString(4)}-${this.randomString(4)}`;
  }

  generateMsgId(length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  isMobile() {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
  lightenColor(hex: string, percent: number) {
    // Convert hex to RGB
    if (!hex) {
      return hex;
    }
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    // Increase each color component by the given percentage
    r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
    g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
    b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

    // Convert RGB back to hex
    const newColor = `#${((1 << 24) + (r << 16) + (g << 8) + b)
      .toString(16)
      .slice(1)}`;
    return newColor;
  }

  isDarkMode() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    return prefersDark.matches ? 'dark' : 'light';
  }
}
