import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../../services/chat.service';
import { CrudService } from '../../services/crud.service';
import { PhoneService } from '../webphone/phone.service';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DIALER_THEME_CONFIGURATION, ROOT_CONFIGURATION, THEME_SETTING } from '../../utils/config';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public rootConfiguration = ROOT_CONFIGURATION;
  @Input() orientation: string = "portrait";
  @Input() hideIndicator: boolean = false;
  @Input()
  title: string;

  @Input() backBtn = false;

  // If this is true, ionic will automatically handle the back button event
  // oterwise an event will be emitted
  @Input() isNav = false;
  @ViewChild('appTimer', {
    static: false
  }) appTimer: any;
  @Output()
  backButtonClick: EventEmitter<never> = new EventEmitter<never>();
  public isWTCRegistered = false;
  private registerSubscriber: Subscription = null;
  private callingStateSubscriber: Subscription = null;
  public isCallingData = null;
  public propertyConfig: any = {};
  private propertyConfigSubscribe: Subscription = null;
  public dialerThemeSetting =DIALER_THEME_CONFIGURATION.light;
  public dialerThemeSettingOld = THEME_SETTING;
  public appHomeLogoutLogo = '../assets/icon/hotel-genie-logo.png'
  public currentUserModeValue = '';
  private currentUserModeValueSubscriber: Subscription = null;
  public isMute: boolean = false
  private _muteSubscriber: Subscription = null;
  onCallStateChangedEventSubscriber: Subscription = null;
  public isCalling: any = null;
  public selectedDevice: string = '';
  public slug: string = '';
  constructor(
    private crudService: CrudService,
    private router: Router,
    private chatService: ChatService, private phoneService: PhoneService,
    private activatedRoute: ActivatedRoute,
    private _zone: NgZone,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.slug = this.crudService.slug.getValue();
    this.onCallStateChangedEventSubscriber = this.phoneService.isCalling$.subscribe(async (e) => {
      this.isCalling = e;
      if (!e || !e?.callStatus) {
        this.phoneService.keypadModel.next({
          to: '', showKeypad: true
        })
        this.appTimer?.clearTimer();
      } else if (e.callStatus === 'connected') {
        this.phoneService.keypadModel.next({
          to: this.phoneService.keypadModel.value.to, showKeypad: false
        })
        this.appTimer?.startTimer();
        setTimeout(async () => {
          const deviceList = await this.phoneService.getdevicelist() as string[];
          if (deviceList?.length && this.utilService.isMobileDevice()) {
            const hasHeadsetDevice = deviceList[0].split('\r\n').filter((name) => !!name && (name.toLowerCase().includes('headset') || name.toLowerCase().includes('earpiece')));
            if (hasHeadsetDevice?.length) {
              this.selectedDevice = hasHeadsetDevice[0];
              await this.phoneService.setdevice(hasHeadsetDevice[0]);
            }
          }
        }, 1000);
      } else {
        this.appTimer?.clearTimer();
      }
    });
    this.propertyConfigSubscribe = this.crudService.propertyConfig$.pipe(
      tap(async (propertyConfig) => {
        this.propertyConfig = propertyConfig;
        this.dialerThemeSettingOld = this.propertyConfig?.dialerThemeSetting?.value || this.dialerThemeSettingOld;
        this.appHomeLogoutLogo = this.propertyConfig?.appHomeLogoutLogo?.value || '../assets/icon/hotel-genie-logo.png';
      })
    ).subscribe();
    this.currentUserModeValueSubscriber = this.crudService.currentUserMode.pipe((tap((currentUserModeValue) => {
      this.currentUserModeValue = currentUserModeValue
    }))).subscribe();
    this.registerSubscriber = this.phoneService.isUserRegistered$
    .pipe(tap((value: boolean) => {
      this.isWTCRegistered = value;
    })).subscribe();
    this._muteSubscriber = this.phoneService.isMute$
      .pipe(tap((isMute) => {
        this.isMute = isMute
      }))
      .subscribe();
    this.callingStateSubscriber = this.phoneService.isCalling$.pipe(
      tap((data) => {
        this._zone.run(() => {
          this.isCallingData = data;
          console.log(this.isCallingData ,'isCallingData?.callType ')
          if (data?.callType === 'incoming') {
            this.goto();
          }
        });
      }),
    ).subscribe();
   
  }

  ngOnDestroy() {
    this.registerSubscriber?.unsubscribe();
    this.callingStateSubscriber?.unsubscribe();
    this.propertyConfigSubscribe?.unsubscribe();
    this.currentUserModeValueSubscriber?.unsubscribe();
    this._muteSubscriber?.unsubscribe();
  }


  goto() {
    console.log('activatedRoute', this.activatedRoute)
    if (this.orientation == 'portrait') this.router.navigateByUrl("/dialer")
  }

  backButtonClicked() {
    this.backButtonClick.emit();
  }

  async logout() {
    this._zone.run(async () => {
      const user = this.crudService.currentUserValue;
      console.log(user)
      if (user && this.crudService.currentUserModeValue === 'staff') {
        await this.crudService.logout('user/logout', user).toPromise();
      }
      this.crudService.setAuthToken = null;
      this.chatService.onDisconnect();
      localStorage.clear();
      await this.phoneService.unregister();
      if(this.crudService.currentUserModeValue === 'staff') {
        this.router.navigate(["admin"]);
      } else {
        this.router.navigate(['login']);
      }
    });
  }
  async muteUnmuteCall() {
    await this.phoneService.muteUnmuteCall(!this.phoneService.isMute$.getValue());
  }
  async terminateCall() {
    await this.phoneService.terminateCall();
  }
}
