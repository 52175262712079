import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RoomServiceModalComponent } from "./home/room-service/room-service.page"
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { ComponentsModule } from './component/components.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { ChatService } from './services/chat.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddOccupantPageComponent } from './add-occupant/add-occupant.page';
import { AliceServiceModalComponent } from './home/alice-service/alice-service.page';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StaticConversationModalComponent } from './conversation/static-conversation-modal/static-conversation-modal.page';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { GuestCallingService } from './services/calling';
import { SelectDropDownModule } from 'ngx-select-dropdown';
const socketConfig: SocketIoConfig = { url: environment.WS_ENDPOINT, options: { autoConnect: false }, };


@NgModule({
    declarations: [AppComponent, RoomServiceModalComponent, AddOccupantPageComponent, AliceServiceModalComponent, StaticConversationModalComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            mode: 'md'
        }),
        AppRoutingModule,
        HttpClientModule,
        ComponentsModule,
        SocketIoModule.forRoot(socketConfig),
        SelectDropDownModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideMessaging(() => getMessaging()),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptorService,
            multi: true,
        },
        ChatService, GuestCallingService,
    ],
    bootstrap: [AppComponent],
    exports: [SocketIoModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class AppModule { }