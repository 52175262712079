import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CrudService } from 'src/app/services/crud.service';
import { UtilService } from 'src/app/services/util.service';
import SwiperCore , {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper';

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);

@Component({
  selector: 'home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit, OnDestroy {

  @Input() appHomeLogos: Record<string, any>;
  @Input() sname: string;
  @Input() name: string;
  @Input() room: string;
  @Input() isGuestLogin: any;
  appHomeLogosModified:any[] = [];
  autoPlayTime: number = 0;
  orientationSubscribe: Subscription;
  orientation: string = '';
  constructor(private crudService: CrudService, private utilService: UtilService) { }

  ngOnInit(): void {
    this.autoPlayTime = (this.appHomeLogos?.swiperSetting?.autoPlayTime || 0) * 1000;
    this.mapperMessage();
    this.orientationSubscribe = this.utilService.getOrientation().pipe(
      tap((orientation) => {
        this.orientation = orientation;
      })
    ).subscribe();
  }

  async onBannerClick(logo, i: number) {
    if (logo.externalLink && logo.isAd) {
      let url = logo.externalLink;
      if (!url.includes('http') && !url.includes('https')) {
        url = `//${url}`;
      }
      Browser.open({
        url
      });
      this.appHomeLogos.logos[i].count = this.appHomeLogos.logos[i].count + 1;
      this.crudService.savePropertyButtonConfig('property', 'appHomeLogos', {
        propertyId: localStorage.getItem('propertyId'),
        buttons: {
          value: {
            logos: this.appHomeLogos.logos,
            swiperSetting: this.appHomeLogos.swiperSetting
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.orientationSubscribe.unsubscribe) {
      this.orientationSubscribe.unsubscribe();
    }
  }

  mapperMessage() {
    this.appHomeLogosModified = this.appHomeLogos.logos.map((data) => {
      let message = data.message
      if (message) {
        const guestFirstName = new RegExp('{{GUEST_FIRSTNAME}}', 'g');
        message = message.replace(guestFirstName, this.isGuestLogin ? 'Guest' : localStorage.getItem('firstName'))
        const guestLastName = new RegExp('{{GUEST_LASTNAME}}', 'g');
        message = message.replace(guestLastName,  this.isGuestLogin ? '' :localStorage.getItem('lastName'))
        const roomNumber = new RegExp('{{ROOM_NUMBER}}', 'g');
        message = message.replace(roomNumber, localStorage.getItem('room'));
      }
      return {
        ...data,
        message
      };
    })
  }
  
}
