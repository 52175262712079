import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { environment } from 'src/environments/environment.prod';
import { PhoneService } from '../component/webphone/phone.service';


@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {

  url: string = environment.deeplink_url
  constructor(
    private router: Router,
    private phoneservices: PhoneService
  ) { }

  isActivated() {
    App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
      console.info('App opened with URL:', JSON.stringify(data));
      // this.navigateTo(data.url);
    });
  }

  async action(actionEvent: string) {
    switch (actionEvent) {
      case "acceptcall":
        await this.phoneservices.acceptCall();
        await this.router.navigate(['dialer']);
        break;
      case "declinecall":
        await this.phoneservices.terminateCall();
        await this.router.navigate(['dialer']);
        break;
      case "callback":
        await this.router.navigate(['dialer']);
        break;
    }
  }
}
