import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, } from '@angular/core';
import { Platform } from '@ionic/angular';
import { UtilService } from './services/util.service';

import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { NetworkService } from './services/network';
import { GuestCallingService } from './services/calling';
import { DeeplinkService } from './services/deeplink';
import { FcmProvider } from './services/fcm';
import { Network } from '@capacitor/network';
import { ChatService } from './services/chat.service';
import { CrudService } from './services/crud.service';
import { PhoneService } from './component/webphone/phone.service';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { BackgroundRunnerProvider } from './services/backgroundrunner';
import { RouteConfigLoadEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public isLoggedIn: boolean = false;
  private slugConfigurationSubscribe: Subscription = null;
  public slugConfiguration: any = {};
  private propertyConfigurationSubscribe: Subscription = null;
  public propertyConfiguration: any = {};
  public isFirstLoad = false;
  public isShowEmergencyAlert: boolean = false;
  public isAdmin: boolean = false;
  public isAdminHomePage: boolean = false;
  constructor(
    private backgroundrunner: BackgroundRunnerProvider,
    private netservice: NetworkService,
    private platform: Platform,
    private fcm: FcmProvider,
    private deeplink: DeeplinkService,
    private utilService: UtilService,
    private guestCallingServices: GuestCallingService,
    private chatService: ChatService,
    private crudService: CrudService,
    private phoneService: PhoneService,
    private router: Router
  ) {
    this.platform.ready().then(async () => {
      this.deeplink.isActivated();
      if (this.platform.is('capacitor')) {
        StatusBar.setBackgroundColor({
          color: '#002856'
        })
        StatusBar.setStyle({
          style: Style.Dark
        })
        SplashScreen.hide()
      }
    })
  }

  async ngOnInit() {
    let timeoutInstance = setTimeout(() => {
      this.isFirstLoad = true;
      console.info('first load set true via timeout')
    }, 1500);
    this.crudService.currentUser.pipe(
      tap((user) => {
        this.isLoggedIn = !!user;
        this.isAdmin = this.isLoggedIn && this.crudService.currentUserModeValue === 'staff';
      })
    ).subscribe();
    this.crudService.currentUserMode.pipe(
      tap((mode) => {
        this.isLoggedIn = !!this.crudService.currentUserModeValue;
        this.isAdmin = this.isLoggedIn && mode === 'staff';
      })
    ).subscribe();
    this.crudService.isAdminHomePage$.pipe(
      tap((isAdminHomePage) => {
        this.isAdminHomePage = isAdminHomePage
      })
    ).subscribe();
    this.crudService.showEmergencyAlert$.pipe(
      tap((isShowEmergencyAlert) => {
        this.isShowEmergencyAlert = isShowEmergencyAlert;
      })
    ).subscribe();
    await this.utilService.checkPermissions();
    await this.backgroundrunner.init();
    this.router.events
      .pipe(
        filter(event => event instanceof RouteConfigLoadEnd),
        tap(() => {
          if (!this.isFirstLoad) {
            clearTimeout(timeoutInstance);
            timeoutInstance = null;
            this.isFirstLoad = true;
          }

          console.info('first load set true via navigation')
        })
      )
      .subscribe();
    this.phoneService.initRegister(() => {
      this.phoneService.setSipDefaultParameter();
    })
    this.crudService.initializeAudioContext();
  }

  async ngAfterViewInit() {
    try {
      this.slugConfigurationSubscribe = this.crudService.slugConfiguration$.pipe(
        tap((slugConfiguration) => {
          this.slugConfiguration = slugConfiguration;
        })
      ).subscribe();
      const uid: any = localStorage.getItem('guestId');
      if (uid) await this.fcm.init(uid);

      this.propertyConfigurationSubscribe = this.crudService.propertyConfig$.pipe(
        tap((propertyConfiguration) => {
          this.propertyConfiguration = propertyConfiguration;
          this.phoneService.propertyConfiguration = propertyConfiguration;
          const uid: any = localStorage.getItem('guestId');
          const roomNumber = localStorage.getItem('room');
          const propertyId = localStorage.getItem('propertyId');
          if (!this.propertyConfiguration.sipRealm?.value) {
            return;
          }
          if (uid && roomNumber && !this.phoneService.isWebRtcInitialize && Object.keys(this.propertyConfiguration).length) {
            this.phoneService.isWebRtcInitialize = true;
            const sipParameters = [{
              key: 'resetsettings', value: true
            }, {
              key: 'serveraddress', value: this.propertyConfiguration.sipRealm?.value
            }, {
              key: 'username', value: roomNumber
            }, {
              key: 'password', value: this.propertyConfiguration.sipWebRtcPassEncrypted?.value || this.propertyConfiguration.sipPassEncrypted?.value
            }, {
              key: 'customsipheader', value: this.propertyConfiguration.enableCallingPush?.value ? `X-HG: PID=${propertyId}\r\nX-HG-PUSH: app-id=io.hotelgenie.app^pn-tok=121212^pn-type=hotelgenie` : `X-HG: PID=${propertyId}`
            }, {
              key: 'webrtcserveraddress', value: this.propertyConfiguration.sipWssAddress?.value || this.propertyConfiguration.sipWssAddressBackup?.value
            }, {
              key: 'loglevel', value: 5
            }, {
              key: 'ssidcode', value: "k8qhh3mrt"
            }, {
              key: 'cancloseaudioline', value: 3
            }, {
              key: 'singleaudiostream', value: 5
            }, {
              key: 'useragent', value: `hotelGenie/${navigator.userAgent}`
            }, {
              key: 'displayname', value: this.crudService.propertyConfig$.value.skipButtonCall ?
                `RM${localStorage.getItem('room')}-${localStorage.getItem('guestDialName') || this.guestCallingServices.guestName}` :
                `RM${localStorage.getItem('room')}-${localStorage.getItem('firstName')}`
            },
            {
              key: 'regtimeout', value: 0
            }];
            this.phoneService.propertyId = propertyId;
            if (this.crudService.propertyConfig$.value['featureWebRTC'].value) {
              if (this.phoneService.isWebRtcLoaded$.value) {
                this.phoneService.onWTCStart([...sipParameters, ...(this.crudService.propertyConfig$.value?.advanceSipParameters?.value || [])], this.crudService.propertyConfig$.value?.enableInboundConfig?.value);
              } else {
                const unsub = this.phoneService.isWebRtcLoaded$.pipe(tap((value) => {
                  if (value) {
                    unsub?.unsubscribe()
                    this.phoneService.onWTCStart([...sipParameters, ...(this.crudService.propertyConfig$.value?.advanceSipParameters?.value || [])], this.crudService.propertyConfig$.value?.enableInboundConfig?.value);
                  }
                })).subscribe()
              }
            }
          }

        })).subscribe();
      this.netservice.initializeNetworkEvents();
      if (this.guestCallingServices?.token) this.guestCallingServices.saveGuestToken()
      if (!this.guestCallingServices?.token) {
        try {
          this.crudService.setSlug();
          await this.utilService.loadSlugConfig();
        } catch (error) {
          this.crudService.setSlug();
          this.router.navigate(['login']);
        }
      }
    } catch (err) {
      console.error("Ng After View Init =============>", err);
    }
  }



  @HostListener('window:resize', ['$event'])
  onResize() {
    this.utilService.setOrientation()
  }

  ngOnDestroy(): void {
    this.chatService.onDisconnect()
    Network.removeAllListeners();
    this.slugConfigurationSubscribe?.unsubscribe();
    this.propertyConfigurationSubscribe?.unsubscribe();
  }

}
