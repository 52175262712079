import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'screen-lock',
  templateUrl: './screen-lock.component.html',
  styleUrls: ['./screen-lock.component.css']
})
export class ScreenLockComponent {


  @Output() unlockActions: any = new EventEmitter();
  constructor() { }


  componentAction(function_name: string) {
    this.unlockActions.emit({
      "action": function_name
    })
  }
}
