import { Injectable } from "@angular/core";
import { App } from "@capacitor/app";
import { BackgroundRunner } from '@capacitor/background-runner';
@Injectable({
    providedIn: 'root',
})
export class BackgroundRunnerProvider {

    interval: any;

    constructor(
    ) {

    }

    async init() {
        App.addListener('appStateChange', async ({ isActive }) => {
            try {
                if (!isActive) {
                    const permissions = await BackgroundRunner.requestPermissions({
                        apis: ['notifications', 'geolocation'],
                    });
                    console.log('permissions', permissions);
                }
            } catch (err) {
                console.log(`ERROR in background app: ${err}`);
            }

        });
    }
}