import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  public activatedEmitter = new BehaviorSubject<boolean>(false); // contains user's activation status
  public obs$ = this.activatedEmitter.asObservable(); // subscribes to the changes in user'sauth status

  constructor(
    private router: Router,
    private crudService: CrudService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return (Object.keys(this.crudService.slugConfiguration$.value || {}).length && Object.keys(this.crudService.propertyConfig$.value || {}).length && Object.keys(this.crudService.pwaConfiguration$.value || {}).length ? of([{
      response_data: this.crudService.slugConfiguration$.value
    }, {
      configuration: this.crudService.propertyConfig$.value
    }, {
      response_data: {
        configuration: this.crudService.pwaConfiguration$.value
      }
    }, true ]) : this.crudService.getAllConfigurations()).pipe(
      map(([slugConfig, propertyConfig, pwaConfiguration, alreadyHasInfo]) => {
        this.crudService.setSlugConfiguration = slugConfig?.response_data;
        this.crudService.setPropertyConfig = propertyConfig?.configuration;
        this.crudService.setPWAConfiguration = pwaConfiguration?.response_data;
        if (this.crudService.getAuthToken) {
        } else if (localStorage.getItem('token')) {
          this.crudService.setAuthToken = localStorage.getItem('token');
        }
        if (this.crudService.currentUserModeValue === 'staff') {
          this.router.navigate(['admin/home']);
          return false;
        }
        if (this.crudService.getAuthToken) {
          if (!alreadyHasInfo) {
            this.crudService.requestSubscription({
              guestId: Number(localStorage.getItem('guestId'))
            });
          }
          if (route.data?.page && propertyConfig?.configuration?.webAppTabConfiguration?.value) {
            if (propertyConfig?.configuration?.webAppTabConfiguration?.value[route.data?.page]) {
              return true;
            } else {
              if (propertyConfig?.configuration?.webAppTabConfiguration?.value?.home) {
                this.router.navigate(['home']);
                return false;
              }
              if (propertyConfig?.configuration?.webAppTabConfiguration?.value?.phone) {
                this.router.navigate(['dialer']);
                return false;
              }
              if (propertyConfig?.configuration?.webAppTabConfiguration?.value?.tripPlanner) {
                this.router.navigate(['trip-vault']);
                return false;
              }
              if (propertyConfig?.configuration?.webAppTabConfiguration?.value?.chat) {
                this.router.navigate(['conversation']);
                return false;
              }
              localStorage.clear()
              this.router.navigate(['login']);
              return false;
            }
          }
          return true;
        } else if (localStorage.getItem('token')) {
          this.crudService.setAuthToken = localStorage.getItem('token');
          return true;
        }
        this.router.navigate(['login']);
        return false;
      })
    );
  }
}
