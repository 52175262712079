import { Component, NgZone, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { ActionSheetController, GestureController } from '@ionic/angular';
import { PhoneService } from '../../webphone/phone.service';
import { KeypadService } from '../../webphone/keypad.service';
import { CrudService } from 'src/app/services/crud.service';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DIALER_THEME_CONFIGURATION } from '../../../utils/config';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss']
})
export class KeypadComponent implements OnInit, OnDestroy {
  @Input()
  public propertyId;
  public dialerThemeSetting = DIALER_THEME_CONFIGURATION.light
  activeIndex = ''
  @Input() groupedContacts: any[] = [];
  @Input() phonebook: any[] = [];
  constructor(private gestureCtrl: GestureController,
    private _zone: NgZone, private phoneService: PhoneService, private keypadService: KeypadService, private crudService: CrudService, private actionSheetCtrl: ActionSheetController, private utilService: UtilService) {
  }
  timer: any = 0;
  preventSimpleClick: boolean = false;
  @ViewChild('callBtn', {
    static: false
  }) callBtn: any;
  private lastOnStart: number = 0;
  private DOUBLE_CLICK_THRESHOLD: number = 500;
  public isWTCRegistered = true;
  private registerSubscriber: Subscription = null;
  private keypadValueSubscriber: Subscription = null;
  private onCallStateChangedEventSubscriber: Subscription = null;
  public isCalling: any = {};
  public keypadModel: string = '';
  public showKeypad: boolean = true;
  public isPhoneDirectory: boolean = false;
  public searchQuery: string = '';
  public filteredContacts: any[] = [];
  private _pwaConfigurationSubscribe: Subscription = null;
  @ViewChild('phoneModal', {
    static: false
  }) phoneModal: any;
  dialAction() {
    this.phoneService.callTo({ to: this.phoneService.keypadModel.value.to, propertyConfig: this.crudService.propertyConfig$.value })
  }

  redialAction() {
    this.phoneService.keypadModel.next({
      to: this.phoneService.redialNumber, showKeypad: true
    })
    this.phoneService.callTo({ to: this.phoneService.redialNumber, propertyConfig: this.crudService.propertyConfig$.value })
  }

  ngOnDestroy(): void {
    this.registerSubscriber?.unsubscribe();
    this.onCallStateChangedEventSubscriber?.unsubscribe();
    this.keypadValueSubscriber?.unsubscribe();
    this.isPhoneDirectory = false;
    this.phoneModal?.dismiss?.()
    this._pwaConfigurationSubscribe?.unsubscribe();
  }

  simpleClickFunction(): void {
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 400;

    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        //whatever you want with simple click go here
        this._zone.run(() => {
          this.dialAction();
        })
      }
    }, delay);

  }

  doubleClickFunction(): void {
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    //whatever you want with double click go here
    this._zone.run(() => {
      this.redialAction();
    })
  }

  async ngOnInit() {
    this.registerSubscriber = this.phoneService.isUserRegistered$
      .pipe(tap((value: boolean) => {
        // this.isWTCRegistered = value;
      }))
      .subscribe();
    this.keypadValueSubscriber = this.phoneService.keypadModel
      .pipe(tap((value: {
        to: string, showKeypad: boolean;
      }) => {
        this.keypadModel = value.to;
        this.showKeypad = value.showKeypad
      }))
      .subscribe();
    this.onCallStateChangedEventSubscriber = this.phoneService.isCalling$
      .pipe(
        tap((e) => {
          this._zone.run(() => {
            this.isCalling = e;
            if (!e || !e?.callStatus) {
              this.phoneService.keypadModel.next({
                to: '', showKeypad: true
              });
            }
          })
        })
    ).subscribe();
    this._pwaConfigurationSubscribe = this.crudService.pwaConfiguration$.pipe(
      tap((pwaConfiguration) => {
        this.dialerThemeSetting = pwaConfiguration?.themeConfiguration[this.utilService.isDarkMode()] || DIALER_THEME_CONFIGURATION[this.utilService.isDarkMode()];
      })
    ).subscribe();
    setTimeout(() => {
      const gesture = this.gestureCtrl.create({
        gestureName: 'dbclick',
        el: this.callBtn.el as any,
        threshold: 0,
        onStart: () => { this.onStart(); }
      });
      gesture.enable();
    }, 2000)
  }

  private onStart() {
    const now = Date.now();
    if (Math.abs(now - this.lastOnStart) <= this.DOUBLE_CLICK_THRESHOLD) {
      this.doubleClickFunction();
      this.lastOnStart = 0;
    } else {
      this.lastOnStart = now;
      this.simpleClickFunction();
    }
  }


  getAudioDeviceIcon() {
    if (this.phoneService.selectedAudioDevice === 'bluetooth') {
      return 'bluetooth';
    } else if (this.phoneService.selectedAudioDevice === 'headset') {
      return 'headset';
    } else {
      return 'volume-high-outline';
    }
  }

  public terminateCall() {
    return this.phoneService.terminateCall()
  }

  public pressBackSpace() {
    this.keypadService.pressBackSpace();
  }

  public pressClear() {
    this.keypadService.pressClear();
  }

  public pressDigit(digit: string) {
    this.activeIndex = digit
    this.keypadService.pressDigit(digit);
    if (!this.phoneService.isUserRegistered$.value && !this.phoneService.registerGlobalUserInProgress) {
      // this.phoneService.registerGlobalUser(this.crudService.propertyConfig$.value)
    }

    setTimeout(() => {
      this.activeIndex = '';
    }, 200);
  }
  modalOpen() {
    this.searchQuery = '';
    this.handleFilteredGroupedContacts()
    this.isPhoneDirectory = true
  }

  onPhoneDirCall(item) {
    this.isPhoneDirectory = false;
    this.phoneService.keypadModel.next({
      to: item?.number || '', showKeypad: true
    });
    if (item?.number) {
      return this.phoneService.callTo({ to: item.number, propertyConfig: this.crudService.propertyConfig$.value })
    }
  }

  handleFilteredGroupedContacts() {
    this.filteredContacts = [];
    // If there's no search query or it's just spaces, return all contacts
    if (this.searchQuery === '') {
      this.filteredContacts =[...this.groupedContacts] ;
    } else {
      const search = this.searchQuery.toLowerCase();
    
      const filteredGroups = this.groupedContacts
        .map(group => {
          const filteredGroup = group.groupValue.filter(contact => {
            const nameMatch = contact.name && contact.name.toLowerCase().includes(search);
            const phoneMatch = contact.number && contact.number.includes(search);
            return nameMatch || phoneMatch;
          });
  
          return {
            groupKey: group.groupKey,
            groupValue: filteredGroup
          };
        })
        .filter(group => group.groupValue.length > 0) || [];
  
      this.filteredContacts = filteredGroups;
      
    }
  }
  
 
  


}
