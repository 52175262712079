import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UtilService } from '../../../services/util.service';

@Component({
  selector: 'home-btns',
  templateUrl: './home-btns.component.html',
  styleUrls: ['./home-btns.component.scss']
})
export class HomeBtnsComponent implements OnInit, OnDestroy {

  @Input() homeButtonTheme: string;
  @Input() homeButtonLandScapeTheme: string;
  @Input() callButton: Array<any> = [];
  @Input() isGuestLogin: boolean;
  @Output() actions: any = new EventEmitter();
  public theme: string = '';
  public orientation: string = '';
  public orientationSubscribe: any;
  constructor(private utilService: UtilService) { }

  ngOnInit(): void {
    this.orientationSubscribe = this.utilService.getOrientation().pipe(
      tap((orientation) => {
        this.theme = orientation === 'landscape' ? this.homeButtonLandScapeTheme : this.homeButtonTheme;
        this.orientation = orientation;
      })
     ).subscribe();
  }

  componentAction(btn: string = null) {
    this.actions.emit(btn)
  }

  ngOnDestroy(): void {
      if (this.orientationSubscribe.unsubscribe) {
        this.orientationSubscribe.unsubscribe();
      }
  }

}
