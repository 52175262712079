import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { RouterModule } from '@angular/router';
import { ModalComponent } from "./modal/modal.component";
import { OnloadingComponent } from "./onloading/onloading.component";
import { KeypadComponent } from './calling/keypad/keypad.component';
import { ScreenLockComponent } from './calling/screen-lock/screen-lock.component';
import { HomeBtnsComponent } from "./homecomponenets/home-btns/home-btns.component";
import { HomeBannerComponent } from "./homecomponenets/home-banner/home-banner.component";
import { IncomingCallComponent } from "./calling/incoming-call/incoming-call.component";
import { OutgoingCallComponent } from "./calling/outgoing-call/outgoing-call.component";
import { TimerComponent } from "./calling/timer/timer.component";
import { SwiperModule } from "swiper/angular";
import { WakeWordComponent } from "./wake-word/wake-word.component";
import { DatePickerComponent } from "./data-picker/data-picker.component";
import { LongPressDirective } from "../long-press.directive";

export const components: any = [
  HeaderComponent, FooterComponent, ModalComponent,
  OnloadingComponent, KeypadComponent, ScreenLockComponent,
  HomeBtnsComponent, HomeBannerComponent,
  OutgoingCallComponent, IncomingCallComponent, TimerComponent,
  WakeWordComponent, DatePickerComponent, LongPressDirective
]


@NgModule({
  declarations: components,
  imports: [
    IonicModule, CommonModule, FormsModule, RouterModule,
    SwiperModule
  ],
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class ComponentsModule { }
