import { Component, OnInit } from '@angular/core';
import { IonDatetime, ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment/moment';


@Component({
  selector: 'app-data-picker',
  templateUrl: './data-picker.component.html',
  styleUrls: ['./data-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  public nowTime;
  public displayFormat: string = ''
  public valueFormat: string = '';
  constructor(private modalCtrl: ModalController, private navParams: NavParams) {

  }

  ngOnInit(): void {
    if (this.navParams.get("displayFormat")) {
      this.displayFormat = this.navParams.get("displayFormat");
    }
    if (this.navParams.get("valueFormat")) {
      this.valueFormat = this.navParams.get("valueFormat");
    }
    if (this.navParams.get("currentTime")) {
      this.nowTime = moment(Number(this.navParams.get("currentTime"))).format(this.valueFormat);
    }
  }

  async confirm(timePicker: IonDatetime) {
    await timePicker.confirm();
    this.modalCtrl.dismiss(timePicker.value, 'confirm');
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
